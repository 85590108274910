import React, { useEffect } from "react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql, Link } from "gatsby"
import Glide from "@glidejs/glide"
import { useMediaPredicate } from "react-media-hook"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { HeadingBasic } from "../components/heading/heading"
import { Container } from "../components/grid/grid"
import {
  WpCaseStudyGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../components/utils/render-functions"

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../components/cover-main/cover-main"
import { CoverFeatured } from "../components/cover-featured/cover-featured"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const CaseStudyDetail = ({ pageContext, location, category, slug }) => {
  const { details } = pageContext
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    const gallery = document.querySelectorAll("#gallery")

    if (details) {
      gallery.forEach(item => {
        const slider = new Glide(item, {
          perView: 3,
          gap: 10,
          startAt: 0,
          breakpoints: {
            992: {
              perView: 1,
            },
          },
        })

        slider.mount()
      })
    }
  }, [details])

  useEffect(() => {
    if (location.pathname != null) {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }
  }, [])

  const wordPress = details && details.blocks
  const seo = details && details.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout
      hideFooter={wordPress ? false : true}
      currentLocation={location.pathname}
    >
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data?.imgCoverMain?.childImageSharp?.fluid || details.featuredImage.sourceUrl
        }
        twitterImg={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid || details.featuredImage.sourceUrl
        }
        img={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid || details.featuredImage.sourceUrl
        }
      />

      {wordPress && (
        <>
          <Helmet>
            <body className="bd-page" />
          </Helmet>

          {/* <CoverMain
            h1={(details && details.title) || "Title"}
            img={
              cover
                ? cover.attributes.url
                : data.imgCoverMain.childImageSharp.fluid
            }
            alt={(details && details.title) || "Title"}
            className="cover-md"
          >
            <Breadcrumbs
              path={location.pathname}
              className="breadcrumb-light"
            />
          </CoverMain> */}
        </>
      )}

      <div className="row">
        {wordPress &&
          wordPress.map((item, i) => {
            switch (item.name) {
              case "core/group":
              return (
                <div className={wordPress.length === 2 ? "col-md-6" : "col-md-4"}  key={`${item.name}-${i}`}>
                  <div className="pb-0">
                    <WpCaseStudyGroup data={item} />
                  </div>
                </div>
              )
            }
          })
        }
      </div>

      {/* {wordPress &&
        wordPress.map((item, i) => {
          switch (item.name) {
            case "core/heading":
              return (
                <div key={`heading-${i}`} className="container">
                  <div className={i === 0 ? "py-main" : ""}>
                    <HeadingBasic h2={item.attributes.content} />
                  </div>
                </div>
              )
            case "core/list":
                return (
                  <div className="heading heading-basic" style={i === 0 ? "" : {marginTop:"3rem"}} id={i}>
                    <ul>
                      <ReactMarkdown
                        source={item.attributes.values}
                        // source=""
                        escapeHtml={false}
                      />
                    </ul>
                  </div>
                )
            case "core/image":
              return (
                <div key={`core-image-${i}`} className="pb-4">
                  <img
                    alt="image"
                    src={item.attributes.url}
                    // src=""
                    className="img-fluid"
                  />
                </div>
              )
            case "core/buttons":
              if (
                item.innerBlocks[0].attributes.url &&
                item.innerBlocks[0].attributes.url.split(".").length > 1
              ) {
                return (
                  <div key={`button=${i}`} className="container">
                    <div className={i === 0 ? "py-main" : "pb-main"}>
                      <HeadingBasic>
                        <a
                          href={item.innerBlocks[0].attributes.url}
                          target="_blank"
                          className="btn btn-link fx-underline"
                          rel="noopener noreferrer"
                          key={`${item.innerBlocks[0].attributes.url}-${i}`}
                        >
                          {item.innerBlocks[0].attributes.text} &nbsp;
                          <i className="far fa-external-link"></i>
                        </a>
                      </HeadingBasic>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={`button-${i}`} className="container">
                    <Link
                      key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      to={item.innerBlocks[0].attributes.url}
                      className="btn btn-link fx-underline"
                    >
                      {item.innerBlocks[0].attributes.text}
                    </Link>
                  </div>
                )
              }
            case "core/paragraph":
              return (
                <div
                  key={`headingbasic-${i}`}
                  className={`${i === 1 ? "py-main" : "py-3"}`}
                >
                  <div className="container container-md">
                    <HeadingBasic text={[item.attributes.content]} />
                  </div>
                </div>
              )
            case "core/group":
              if (
                i === 1 ||
                (wordPress[i - 1] &&
                  wordPress[i - 1].name === "acf/acfgallery") ||
                i === 0
              ) {
                return (
                  <div key={`${item.name}-${i}`} className="py-5">
                    <WpGroup data={item} />
                  </div>
                )
              } else {
                return (
                  <div key={`${item.name}-${i}`} className="pb-5">
                    <WpGroup data={item} />
                  </div>
                )
              }
            case "acf/iconlist":
              return (
                <div
                  key={`iconlist-${i}`}
                  className={
                    i === 0 ? "container py-main" : "container pb-main"
                  }
                >
                  <WpIconlist data={item} />
                </div>
              )
            case "acf/labelvalues":
              return (
                <div key={`${item.name}-${i}`} className="pb-main">
                  <Stats data={item} />
                </div>
              )
            case "core/cover":
              if (i !== 0) {
                return (
                  <div key={`featured-${i}`} className="pb-main">
                    <CoverFeatured
                      label="Featured News"
                      img={
                        item.attributes.url ||
                        data.featured.childImageSharp.fluid
                      }
                      title={item.innerBlocks[0].attributes.content}
                      text={item.innerBlocks[1].attributes.content}
                      btnText={
                        item.innerBlocks[2].innerBlocks[0].attributes.text
                      }
                      btnLink={
                        item.innerBlocks[2].innerBlocks[0].attributes.url
                      }
                    />
                  </div>
                )
              }
              break
            case "acf/acfgallery":
              return (
                <Container
                  className={`${i === wordPress.length - 1 ? "mb-5" : ""}`}
                  key={`gallery-${i}`}
                >
                  <Carousel data={item} />
                </Container>
              )
            default:
              return <div key={`default-${i}`}>{item.name}</div>
          }
          return <div key={`value-${i}`}></div>
        })} */}
    </Layout>
  )
}

export default CaseStudyDetail
